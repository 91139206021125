import React from "react";
import { PrimaryLink } from "../../../components/links";
import { TableData } from "../../../components/layouts/Table";
import { Spinner } from "../../../components/generic";
import { FiPlus } from "react-icons/fi";
import useAdminHook from "../../../hooks/useAdminHook";
import { useAuthContext } from "../../../context/AuthContext";
const AdminListings = () => {
  const tbHeader = [
    // "Administrator",
    // "Phone Number",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email Address",
    // "State",
    // "LGA",
    "Role",
    "Status",
    // "Actions",
  ];
  const { admins, loading } = useAdminHook();
  const { authUser } = useAuthContext();
  return (
    <div>
      {/* SPinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        <h1 className="text-lg font-semibold">Administrators</h1>
        {authUser?.account?.Role === "Super Admin" && (
          <PrimaryLink
            path={`/admin/add`}
            text={"New Admin"}
            width={"w-[140px]"}
            Icon={FiPlus}
          />
        )}
      </div>
      {/* Table Header */}
      <table className="w-full bg-white">
        <thead className="">
          <tr className="border-b border-b-[#E1E1E1]">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-4 text-left text-xs whitespace-nowrap "
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        <tbody className="w-full bg-white">
          {admins.map((item: any, index) => (
            <tr
              className={`${
                index % 2 === 0 ? " bg-[#F9FAFC]" : "bg-white"
              } border-b border-b-[#E1E1E1]`}
              key={index}
            >
              {/* <td className="px-6 py-2">
                <p className="text-sm font-medium text-black">{`${
                  item?.FirstName
                } ${item?.MiddleName ? item?.MiddleName : ""} ${
                  item?.LastName
                }`}</p>
                <p className="text-xs text-zinc-500">{`${item?.Email}`}</p>
              </td> */}
              <TableData item={item?.FirstName} />
              <TableData item={item?.MiddleName ? item?.MiddleName : ""} />
              <TableData item={item?.LastName} />
              <TableData item={item?.Email} />
              {/* <TableData item={item?.State} />
              <TableData item={item?.LGA} /> */}
              <TableData item={item?.Role} />
              <td className="">
                <div className="flex items-center px-6 py-3">
                  <div className="w-[6px] h-[6px] bg-primary rounded-full mr-2"></div>
                  <p className="text-xs">Active</p>
                </div>
              </td>
              {/* <td>
                <div className="flex items-center px-6 py-3">
                  <Link to={`/${slug}/forms/details/${item.id}`}>
                    <FaEdit className="text-primary mx-4 text-lg" />
                  </Link>
                  <RiDeleteBin6Line className="text-red-600 text-lg" />
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminListings;
