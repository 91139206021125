import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { GetAccountDetails } from "../utils/services/api/users";

const useUserHook = () => {
  const [dashboard, setDashboard] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { config, authUser } = useAuthContext();

  const getAdmins = async () => {
    try {
      setLoading(true);
      let temp = await GetAccountDetails(config);
      setDashboard(temp.dashboard);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, [authUser]);
  return { dashboard, loading };
};
export default useUserHook;
