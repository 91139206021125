import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { GetAllManagers } from "../utils/services/api/users";

const useManagerHook = () => {
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);

  const { config, authUser } = useAuthContext();

  const getManagers = async () => {
    try {
      setLoading(true);
      let temp = await GetAllManagers(config);
      setManagers(temp.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    getManagers();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getManagers();
    // eslint-disable-next-line
  }, [authUser]);
  return { managers, loading };
};
export default useManagerHook;
