import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { GetAllAdmins } from "../utils/services/api/users";

const useAdminHook = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  const { config, authUser } = useAuthContext();

  const getAdmins = async () => {
    try {
      setLoading(true);
      let temp = await GetAllAdmins(config);
      setAdmins(temp.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, [authUser]);
  return { admins, loading };
};
export default useAdminHook;
