import React, { useState } from "react";
import useFarmerHook from "../../../hooks/useFarmerHook";
import { TableData } from "../../../components/layouts/Table";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import { TiExportOutline } from "react-icons/ti";
import { CiSearch } from "react-icons/ci";
import PulseLoader from "react-spinners/PulseLoader";
import { Button } from "../../../components/forms";
import { LiaTimesCircleSolid } from "react-icons/lia";
import Papa from "papaparse";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  GetFarmers,
  GetFilteredFarmers,
} from "../../../utils/services/api/users";
import { useAuthContext } from "../../../context/AuthContext";
const FarmerListings = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const tbHeader = [
    "Farmer",
    "Manager",
    "Phone Number",
    "DOB",
    "State",
    "LGA",
    "Ward",
    "Gender",
  ];
  const {
    farmers,
    loading,
    setLoading,
    setCurrentPage,
    totalPages,
    setSearch,
    total,
    search,
    currentPage,
    gender,
    setGender,
  } = useFarmerHook();
  const { config } = useAuthContext();
  const handlePageClick = async (data: any) => {
    setCurrentPage(data?.selected + 1);
  };
  const exportToExcelInBatches = async () => {
    try {
      setLoading(true);
      const tempLimit = 4000; // Number of items to fetch per batch
      const pageCount = Math.ceil(total / tempLimit);
      const zip = new JSZip();

      for (let page = 0; page < pageCount; page++) {
        const tempStart = page * tempLimit;
        let res;
        let tempCurrent = currentPage === undefined ? 1 : currentPage;

        if (search === "" && gender === "") {
          res = await GetFarmers(tempCurrent, tempLimit, config);
        } else {
          res = await GetFilteredFarmers(
            gender,
            search,
            tempCurrent,
            tempLimit,
            config
          );
        }

        const data = await res.data; // Retrieved data from the backend

        // Convert data to Excel
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

        // Convert workbook to binary array
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        // Add Excel file to ZIP with batch number
        zip.file(`Batch_${page + 1}.xlsx`, wbout);
      }

      const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `Farmers_Batches_${currentTimestamp}.zip`); // Download the ZIP file with timestamp
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleGender = (e: any) => {
    setGender(e.target.value);
  };
  console.log(gender);
  return (
    <div className=" pb-52">
      {/* Header */}
      <div className="w-full flex justify-start items-center mb-4">
        <h1 className="text-lg font-semibold">
          Farmers ({total.toLocaleString()})
        </h1>
      </div>
      {/* Filter*/}
      <div className="h-[100vh] w-full scroll-div overflow-scroll bg-white">
        <div className="flex justify-between items-center p-6">
          {/* Filters */}
          <div className={"flex flex-row  gap-4 items-center"}>
            <div className="relative w-64">
              <input
                type="text"
                className="w-full text-xs py-2 pl-8 pr-4 border border-zinc-500 rounded-full focus:border-primaryfocus:outline-none focus:shadow-primary"
                placeholder="Search..."
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    setSearch("");
                  }
                  setSearch(e.target.value);
                }}
              />
              <CiSearch className="text-zinc-500  absolute top-2 left-2 text-lg" />
            </div>
            <div className="flex items-center">
              <select
                onChange={handleGender}
                value={gender}
                className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
              >
                <option selected value="">
                  Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div>
            <button
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              className="text-white w-full text-[10px] bg-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => exportToExcelInBatches()}
            >
              Export
              <TiExportOutline className="text-white ml-2" />
            </button>
          </div>
        </div>
        {loading ? (
          <div className=" m-12  justify-items-center justify-center">
            {" "}
            <PulseLoader
              className=" m-12 justify-center"
              color={"#46A576"}
              loading={loading}
              // cssOverride={override}
              size={14}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <table className="w-full bg-white ">
            <thead className="">
              <tr className="">
                {tbHeader.map((item, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-6 py-3 text-left text-xs whitespace-nowrap"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            {/* Table content */}
            {farmers.map((item: any, index) => (
              <tr
                className={`${
                  index % 2 === 0 ? " bg-[#F9FAFC]" : "bg-white"
                } border-b border-b-[#E1E1E1] cursor-pointer`}
                key={index}
                onClick={() => {
                  setCurrent(index);
                  setModal(true);
                }}
              >
                <td className="px-6 py-2 flex items-center">
                  <p className="text-xs font-medium text-black">{`${
                    item?.FirstName
                  } ${item?.MiddleName ? item?.MiddleName : ""} ${
                    item?.LastName
                  }`}</p>
                </td>
                <TableData
                  item={`${item?.Agent_First_Name} ${item?.Agent_Last_Name}`}
                />
                <TableData item={item?.Phone} />
                <TableData item={item?.DateOfBirth} />
                <TableData item={item?.State} />
                <TableData item={item?.Lga} />
                <TableData item={item?.Ward} />
                <TableData item={item?.Gender} />
              </tr>
            ))}
          </table>
        )}
        {/* Pagination */}
        <div className="px-6 py-6">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"inline-flex items-center -space-x-px"}
            pageClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 border border-gray-300 hover:text-gray-700 hover:bg-primary hover:text-white"
            }
            pageLinkClassName={""}
            previousClassName={
              "block px-3 py-2 text-sm ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-primary hover:text-white"
            }
            previousLinkClassName={""}
            nextClassName={
              "block px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-primary hover:text-white"
            }
            nextLinkClassName={""}
            breakClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-primary hover:text-white"
            }
            breakLinkClassName={
              "px-3 py-2 leading-tight text-sm text-gray-500 bg-white border border-gray-300 hover:bg-primary hover:text-white"
            }
            activeClassName={
              "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
            }
          />
        </div>
      </div>
      {modal && (
        <FarmerDetails
          current={current}
          farmers={farmers}
          setCurrent={setCurrent}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default FarmerListings;

const FarmerDetails = ({ current, setCurrent, farmers, setModal }: any) => {
  const navigate = useNavigate();
  let farmer = farmers[current];
  const cont = "flex items-center justify-between my-2";
  const title = "text-xs font-bold";
  const text = "text-xs font-medium ml-4";
  return (
    <div
      id="popup-modal"
      className="fixed bg-green-900 rounded-lg top-0 border-raidius left-0 right-0 z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
    >
      <div className="w-[50%] bg-white px-8 py-4">
        {/* Edit */}
        <div className="w-full flex justify-between items-center">
          <LiaTimesCircleSolid
            className="font-bold text-black cursor-pointer mr-2"
            size={25}
            onClick={() => setModal(false)}
          />
          <div className="w-[120px]">
            <Button
              text="Edit Details"
              onBtnClick={() => navigate(`/farmer/edit/${farmer.id}`)}
            />
          </div>
        </div>
        <div className="w-full bg-white rounded-md shadow-sm py-3 px-6 flex items-start justify-between">
          {/* Photo */}
          <div className="w-[30%]">
            <img
              src={farmer?.Farmer_Photo}
              alt="Farmer Profile"
              className="w-[150px] h-[150px]"
            />
          </div>
          {/* Desc */}
          <div className="w-[65%]">
            <div className={cont}>
              <p className={title}>{"Name: "}</p>
              <p className={text}>{`${farmer?.FirstName} ${
                farmer?.MiddleName ? farmer?.MiddleName : ""
              } ${farmer?.LastName}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"Manager: "}</p>
              <p
                className={text}
              >{`${farmer?.Agent_First_Name} ${farmer?.Agent_Last_Name}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"Manager Phone No.: "}</p>
              <p className={text}>{`${farmer?.Agent_Phone_Number}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"Gender: "}</p>
              <p className={text}>{`${farmer?.Gender}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"DateOfBirth: "}</p>
              <p className={text}>{`${farmer?.DateOfBirth}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"State: "}</p>
              <p className={text}>{`${farmer?.State}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"LGA: "}</p>
              <p className={text}>{`${farmer?.Lga}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"Ward: "}</p>
              <p className={text}>{`${farmer?.Ward}`}</p>
            </div>
          </div>
        </div>
        {/* Next and Previous */}
        <div className="w-full flex items-center justify-between">
          <div className="w-[100px]">
            <Button
              text="Previous"
              outlined
              onBtnClick={() => setCurrent(current !== 0 && current + 1)}
            />
          </div>
          <div className="w-[100px]">
            <Button
              text="Next"
              onBtnClick={() =>
                setCurrent(current !== farmers?.length && current + 1)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
