import React, { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { HiOutlineBell } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAuthContext } from "../../context/AuthContext";
import { Icon } from "@iconify/react";
const Header = () => {
  const navigate = useNavigate();
  const { authUser, setAuthUser, setToken } = useAuthContext();
  const logout = () => {
    localStorage.clear();
    setAuthUser(null);
    setToken(null);
    navigate("/login");
  };

  return (
    <div className="w-full flex border-b border-b-customGray shadow-xs  h-14">
      <div className="w-[20%] bg-white text-gray">
        <div className="w-full h-full flex items-center justify-center">
          <h1 className="text-3xl font-black text-primary">ACReSAL</h1>
        </div>
      </div>
      <div className="w-[80%]  drop-shadow-2xl flex justify-end items-center px-8">
        <div className="flex items-center">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open && "bg-gray-100",
                    "group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100"
                  )}
                >
                  <HiOutlineBell fontSize={24} />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                    <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                      <strong className="text-gray-700 font-medium">
                        Notifications
                      </strong>
                      <div className="mt-2 py-1 text-sm">
                        This is notification panel.
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="ml-2  flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                <div className="flex flex-items items-center gap-4">
                  <div className="h-10 w-10 rounded-full bg-primary flex items-center justify-center">
                    <p className="text-white font-black text-xl">
                      {authUser?.account?.FirstName?.slice(0, 1)}
                    </p>
                  </div>
                  <div className="flex items-center">
                    {authUser && (
                      <p className="text-xs mr-2">
                        <span className="font-bold">Hello,</span>
                        {`${authUser?.account?.FirstName}`}
                      </p>
                    )}
                    <Icon icon={"fluent:chevron-down-24-filled"} />
                  </div>
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active && "bg-gray-100",
                        "active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      )}
                      onClick={logout}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
