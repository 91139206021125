import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard } from "../pages";
import ProtectedRoute from "./ProtectedRoutes";
import { BaseContainer } from "../components/layouts";
import { Login } from "../pages/auth";
import { AddAdmin, AdminListings } from "../pages/users/admin";
import { AddManager, ManagersListings } from "../pages/users/managers";
import {
  EditFarmerDetails,
  FarmerDetails,
  FarmerListings,
} from "../pages/users/farmers";
import { TrainingListings } from "../pages/activities/training";

const AdminRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<BaseContainer />}>
            <Route index path="/" element={<Dashboard />} />
            <Route path="/admins" element={<AdminListings />} />
            <Route path="/admin/add" element={<AddAdmin />} />
            <Route path="/farmers" element={<FarmerListings />} />
            <Route path="/farmer/:id" element={<FarmerDetails />} />
            <Route path="/farmer/edit/:id" element={<EditFarmerDetails />} />
            <Route path="/managers" element={<ManagersListings />} />
            <Route path="/manager/add" element={<AddManager />} />
            {/* Training */}
            <Route path="/trainings" element={<TrainingListings />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default AdminRoutes;
