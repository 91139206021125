import React from "react";
import SideBarSingleLink from "./SideBarSingleLink";
import DropDown from "./DropDown";

const SideBar = () => {
  const DASHBOARD_SIDEBAR_LINKS = [
    {
      key: "dashboard",
      label: "Dasboard",
      path: `/`,
      icon: "material-symbols-light:home-outline",
      dropdown: false,
    },
    {
      key: "users",
      label: "Users",
      path: "#",
      icon: "mynaui:users",
      dropdown: [
        {
          key: "farmers",
          label: "Farmers",
          path: `/farmers`,
        },
        {
          key: "admins",
          label: "Administrators",
          path: `/admins`,
        },
        {
          key: "managers",
          label: "Managers",
          path: `/managers`,
        },
      ],
    },
    {
      key: "activities",
      label: "Activities",
      path: "#",
      icon: "material-symbols:notes-rounded",
      dropdown: [
        {
          key: "trainings",
          label: "Trainings",
          path: `/trainings`,
        },
      ],
    },
  ];
  return (
    <div className="bg-white text-black w-80  flex-col border-r border-neutral-300 hidden lg:flex md:flex ">
      <div className="pb-8 flex flex-1 flex-col gap-0.5 overflow-y-auto">
        <div className="p-4">
          {DASHBOARD_SIDEBAR_LINKS.map((link, index) => (
            <div className="py-1" key={index}>
              {link.dropdown === false ? (
                <SideBarSingleLink key={index} link={link} />
              ) : (
                <DropDown key={index} link={link} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
