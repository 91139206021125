import React, { FC, ReactEventHandler } from "react";

interface ButtonProps {
  text: string;
  onBtnClick?: ReactEventHandler;
  width?: number;
  Icon?: any;
  outlined?: true;
}

const Button: FC<ButtonProps> = ({
  outlined,
  text,
  onBtnClick,
  width,
  Icon,
}) => {
  return (
    <button
      onClick={onBtnClick}
      type="button"
      className={`${
        outlined ? " bg-faint text-primary" : "bg-primary text-white"
      } ${
        width ? "w-[" + width + "px]" : "w-full"
      } transition cursor-pointer duration-200  focus:ring-opacity-50 py-3 px-4 rounded-sm border border-primary my-5 text-xs shadow-sm hover:shadow-md font-semibold text-center inline-block`}
    >
      <div className="flex items-center justify-center">
        {Icon && (
          <Icon
            size={20}
            className={`${outlined ? "text-primary  " : "text-white "} mr-2`}
          />
        )}{" "}
        <span className="inline-block text-center">{text}</span>
      </div>
    </button>
  );
};

export default Button;
