import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
interface BackLinkProps {
  title?: string;
}
const BackLink: FC<BackLinkProps> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center mb-5">
      <div
        className="flex items-center cursor-pointer mr-2"
        onClick={() => navigate(-1)}
      >
        <AiOutlineLeft color={"black"} size={20} />
        <p className="text-primary text-xs font-medium">Back</p>
      </div>
      {title && <h1 className="text-sm font-semibold ">{title}</h1>}
    </div>
  );
};

export default BackLink;
