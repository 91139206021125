import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { useAuthContext } from "../../context/AuthContext";
import Header from "./Header";
const BaseContainer = () => {
  const navigate = useNavigate();
  const { setAuthUser, setToken } = useAuthContext();

  const isLoggedIn = async () => {
    const user = localStorage.getItem(`acUser`);
    const token = localStorage.getItem(`acToken`);
    if (user && token) {
      setAuthUser(JSON.parse(user));
      setToken(token);
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    isLoggedIn();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      <div className="flex flex-row bg-neutral-200 h-screen w-screen">
        <SideBar />
        <div className="flex flex-col w-full px-8 py-8 overflow-y-auto bg-slate-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BaseContainer;
