import React from "react";
import { Pie, Bar } from "react-chartjs-2";
import "chart.js/auto";
import { assets, farmers, managersIcon } from "../utils/import";
import useUserHook from "../hooks/useUserHook";
import { Link } from "react-router-dom";
const Dashboard = () => {
  const { dashboard } = useUserHook();
  const genderData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [dashboard?.GenderCat[0]?.Total, dashboard?.GenderCat[1]?.Total],
        backgroundColor: ["#46A578", "#F4BE37"],
        hoverBackgroundColor: ["#46A578", "#F4BE37"],
      },
    ],
  };
  const LGATotals: number[] = [];
  const LGALabels: string[] = [];

  const farmersLgaData = dashboard?.FarmersLga;
  if (farmersLgaData) {
    farmersLgaData.forEach((item: any) => {
      LGATotals.push(item.Total);
      LGALabels.push(item.Lga);
    });
  }

  const LGAData = {
    labels: LGALabels,
    datasets: [
      {
        label: "LGAs",
        backgroundColor: "#46A578",
        data: LGATotals,
      },
    ],
  };

  const LGAOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Local Gov't Areas", // Add your X-axis label here
          font: {
            weight: "500",
            size: 20,
          },
          color: "#095330", // Green color
        },
      },
      y: {
        title: {
          display: true,
          text: "Farmers", // Add your Y-axis label here
          font: {
            weight: "500",
            size: 20,
          },
          color: "#095330", // Green color
        },
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };
  const contClass = "h-full bg-white py-5 px-10 shadow-sm rounded-md";
  const roundedCircle = "h-[10px] w-[10px] rounded-full mr-2";

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend color
      },
      tooltip: {
        enabled: false, // Hide the data labels
      },
    },
  };
  function calculatePercentage(a: number, b: number) {
    // Calculate the sum of a and b
    const sum = a + b;

    // Calculate the percentage of a in the total sum
    const percentageOfA = (a / sum) * 100;

    // Return just the percentage
    return percentageOfA.toFixed();
  }

  return (
    <div className="w-full">
      <h1 className="text-lg font-semibold text-black mb-5">Overview</h1>
      {dashboard !== null && (
        <>
          <div className="flex gap-10">
            {/* Managers */}
            <Link to={`/managers`} className="w-[25%] cursor-pointer">
              <div className=" w-full flex items-center p-4 bg-white rounded-lg shadow-xs">
                {" "}
                {/* Image */}
                <div className="w-[50px] h-[50px] rounded-full bg-green-100 flex justify-center items-center">
                  <img src={managersIcon} className="" alt="Managers Icon" />
                </div>
                <div className="ml-8">
                  {dashboard?.TotalManagers.map((item: any, index: number) => (
                    <p
                      className="text-2xl font-black text-green-900 mb-2"
                      key={index}
                    >
                      {item?.Total?.toLocaleString()}
                    </p>
                  ))}
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Managers
                  </p>
                </div>
              </div>
            </Link>
            {/* Farmers */}
            <Link to={`/farmers`} className="w-[25%] cursor-pointer">
              <div className=" w-full flex items-center p-4 bg-white rounded-lg shadow-xs">
                {/* Image */}
                <div className="w-[50px] h-[50px] rounded-full bg-green-100 flex justify-center items-center">
                  <img src={farmers} className="" alt="Farmer Icon" />
                </div>
                <div className="ml-8">
                  {dashboard?.TotalFarmers.map((item: any, index: number) => (
                    <p
                      className="text-2xl font-black text-green-900 mb-2"
                      key={index}
                    >
                      {item?.Total?.toLocaleString()}
                    </p>
                  ))}
                  <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    Farmers
                  </p>
                </div>
              </div>
            </Link>
            {/* Assets */}
            <div className="w-[25%] flex items-center p-4 bg-white rounded-lg shadow-xs">
              {/* Image */}
              <div className="w-[50px] h-[50px] rounded-full bg-green-100 flex justify-center items-center">
                <img src={assets} className="" alt="Farmer Icon" />
              </div>
              <div className="ml-8">
                {dashboard?.TotalAsset.map((item: any, index: number) => (
                  <p
                    className="text-2xl font-black text-green-900 mb-2"
                    key={index}
                  >
                    {item?.Total?.toLocaleString()}
                  </p>
                ))}
                <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                  Assets
                </p>
              </div>
            </div>
          </div>
          {/* Charts */}
          <div className="w-full flex justify-between items-start mt-10">
            {/* Progress Bar */}
            <div className={`${contClass} w-[65%] h-[300px]`}>
              <Bar data={LGAData} options={LGAOptions as any} />
            </div>
            {/* Chart */}
            {/* Gender Chart */}
            <div className={`${contClass} w-[30%]`}>
              <div className="flex">
                <h2 className="text-bold px-5 pt-2">Gender</h2>
              </div>
              <div className="flex justify-center items-center">
                <Pie data={genderData} options={options} />
              </div>
              {/* Numbers */}
              {/* Numbers Header */}
              <div className="w-full flex items-center justify-between border-b border-b-zinc-200 pb-2 mt-5">
                <p className="w-[33%] flex justify-start text-xs font-medium text-zinc-700">
                  Gender
                </p>
                <p className="w-[33%] flex justify-center text-xs font-medium text-zinc-700">
                  Household
                </p>
                <p className="w-[33%] flex justify-end text-xs font-medium text-zinc-700">
                  %
                </p>
              </div>
              <div className="w-full flex items-center justify-between mt-2">
                {/* Gender */}
                <div className="w-[33%] flex justify-start">
                  <div className="">
                    <div className="flex items-center mb-2.5">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            genderData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">Male</p>
                    </div>
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            genderData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">Female</p>
                    </div>
                  </div>
                </div>
                <div className="w-[33%] flex justify-center">
                  <div>
                    <div className="font-bold text-xs mb-2.5">
                      {genderData.datasets[0].data[0].toLocaleString()}
                    </div>
                    <div className="font-bold text-xs">
                      {genderData.datasets[0].data[1].toLocaleString()}
                    </div>
                  </div>
                </div>
                <div className="w-[33%] flex justify-end">
                  <div>
                    <div className="font-medium text-green-500 text-xs mb-2.5">
                      {calculatePercentage(
                        parseInt(genderData.datasets[0].data[0]),
                        parseInt(genderData.datasets[0].data[1])
                      )}
                      {"%"}
                    </div>
                    <div className="font-medium text-green-500 text-xs">
                      {calculatePercentage(
                        parseInt(genderData.datasets[0].data[1]),
                        parseInt(genderData.datasets[0].data[0])
                      )}
                      {"%"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
