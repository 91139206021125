import React, { FC } from "react";
interface SpinnerProps {
  loading: boolean;
}
const Spinner: FC<SpinnerProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="loadingSpinnerContainer">
          <div className="loadingSpinner"></div>
        </div>
      )}
    </>
  );
};

export default Spinner;
