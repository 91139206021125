import { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { GetFarmers, GetFilteredFarmers } from "../utils/services/api/users";

const useFarmerHook = () => {
  const [gender, setGender] = useState("");
  const [farmers, setFarmers] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(200);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const { config, authUser } = useAuthContext();

  const getFarmers = async () => {
    try {
      setLoading(true);
      let temp;
      let tempCurrent = currentPage === undefined ? 1 : currentPage;
      if (search === "" && gender === "") {
        temp = await GetFarmers(tempCurrent, pageSize, config);
      } else {
        temp = await GetFilteredFarmers(
          gender,
          search,
          tempCurrent,
          pageSize,
          config
        );
      }
      console.log(temp);
      setFarmers(temp.data);
      setTotalPages(temp.totalPages);
      // setTotalPages(temp.totalPages);
      setTotal(temp.totalRecords);
      setPageSize(temp.pageSize);
      setCurrentPage(temp.currentPage);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setFarmers([]);
      // toast.error(error);
    }
  };
  useEffect(() => {
    getFarmers();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getFarmers();
    // eslint-disable-next-line
  }, [authUser, currentPage, search, gender]);
  return {
    farmers,
    total,
    currentPage,
    setCurrentPage,
    totalPages,
    loading,
    setSearch,
    pageSize,
    setLoading,
    search,
    gender,
    setGender,
  };
};
export default useFarmerHook;
