import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAuthContext } from "../../context/AuthContext";
interface Props {
  link: any;
}
const SideBarSingleLink: FC<Props> = ({ link }) => {
  const { activePage, setActivePage } = useAuthContext();

  return (
    <Link
      to={link.path}
      className={`${
        link.key === activePage
          ? "bg-green-100 font-medium text-gray border-r-4 border-r-primary"
          : "text-black  hover:text-primary"
      } flex items-center w-full transition-colors duration-300  px-3 py-3 hover:no-underline`}
      onClick={() => setActivePage(link.key)}
    >
      <Icon icon={link.icon} height={20} width={20} />
      <span className="pl-2 text-[12px]">{link.label}</span>
    </Link>
  );
};

export default SideBarSingleLink;
