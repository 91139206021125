import React, { useEffect, useState } from "react";
import { Spinner } from "../../../components/generic";
import { IoIosArrowDropleft } from "react-icons/io";
import { useAuthContext } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { GetFarmerDetails } from "../../../utils/services/api/users";
import { Button } from "../../../components/forms";

const FarmerDetails = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [farmer, setFarmer] = useState<any>(null);
  const navigate = useNavigate();

  const { config } = useAuthContext();

  const cont = "flex items-center justify-between my-2";
  const title = "text-xs font-bold";
  const text = "text-xs font-medium ml-4";
  const onGetFarmerDetails = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await GetFarmerDetails(id?.toString() as string, config);
      setLoading(false);
      setFarmer(res?.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    onGetFarmerDetails();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {/* SPinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="w-full flex justify-start items-center mb-4">
        <IoIosArrowDropleft
          className="font-bold text-black cursor-pointer mr-2"
          size={25}
          onClick={() => navigate("/farmers")}
        />
        <h1 className="text-lg font-semibold">Farmers</h1>
      </div>
      {/* Farmer Details Container */}
      {farmer !== null && (
        <div className="">
          {/* first Section */}
          {/* Desc */}
          <div className="w-[50%]">
            {/* Edit */}
            <div className="w-full flex justify-end items-center">
              <div className="w-[120px]">
                <Button
                  text="Edit Details"
                  onBtnClick={() => navigate(`/farmer/edit/${farmer.id}`)}
                />
              </div>
            </div>
            <div className="w-full bg-white rounded-md shadow-sm py-3 px-6 flex items-start justify-between">
              {/* Photo */}
              <div className="w-[30%]">
                <img
                  src={farmer?.Farmer_Photo}
                  alt="Farmer Profile"
                  className="w-[150px] h-[150px]"
                />
              </div>
              {/* Desc */}
              <div className="w-[65%]">
                <div className={cont}>
                  <p className={title}>{"Name: "}</p>
                  <p className={text}>{`${farmer?.FirstName} ${
                    farmer?.MiddleName ? farmer?.MiddleName : ""
                  } ${farmer?.LastName}`}</p>
                </div>
                {/* <div className={cont}>
              <p className={title}>{"Manager: "}</p>
              <p
                className={text}
              >{`${farmer?.Agent_First_Name} ${farmer?.Agent_Last_Name}`}</p>
            </div>
            <div className={cont}>
              <p className={title}>{"Manager Phone No.: "}</p>
              <p className={text}>{`${farmer?.Agent_Phone_Number}`}</p>
            </div> */}
                <div className={cont}>
                  <p className={title}>{"Gender: "}</p>
                  <p className={text}>{`${farmer?.Gender}`}</p>
                </div>
                <div className={cont}>
                  <p className={title}>{"DateOfBirth: "}</p>
                  <p className={text}>{`${farmer?.DateOfBirth}`}</p>
                </div>
                <div className={cont}>
                  <p className={title}>{"State: "}</p>
                  <p className={text}>{`${farmer?.State}`}</p>
                </div>
                <div className={cont}>
                  <p className={title}>{"LGA: "}</p>
                  <p className={text}>{`${farmer?.Lga}`}</p>
                </div>
                <div className={cont}>
                  <p className={title}>{"Ward: "}</p>
                  <p className={text}>{`${farmer?.Ward}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FarmerDetails;
