import React, { useState } from "react";
import { Button, InputField } from "../../components/forms";
import { toast } from "react-toastify";
import { emailReg } from "../../utils/regex";
import { onLogin } from "../../utils/services/api/auth";
import { Spinner } from "../../components/generic";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../context/AuthContext";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const { setAuthUser, setToken } = useAuthContext();
  const onSubmit = async (data: any) => {
    setLoading(true);
    let formData = {
      email: data.email,
      password: data.password,
    };
    try {
      let res = await onLogin(formData);
      localStorage.setItem(`acUser`, JSON.stringify(res?.data));
      localStorage.setItem(`acToken`, res?.token);
      setAuthUser(res?.data);
      setToken(res?.token);
      navigate(`/`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div className="flex h-screen items-center justify-center bg-primary">
      <Spinner loading={loading} />
      <div className="bg-white p-8 rounded shadow-md w-[350px]">
        <div className="w-full flex items-center justify-center mb-10 mt-5">
          <h1 className="text-lg font-light text-primary">
            Welcome to ACReSAL Backoffice
          </h1>
        </div>
        <div>
          <InputField
            name="email"
            title="Email Address"
            placeholder="Enter Email Address"
            control={control}
            rules={{
              required: "Email Address is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />
          <InputField
            name="password"
            title="Password"
            placeholder="Enter Your Password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />
          <Button text="Login" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};

export default Login;
