import axios from "axios";
import { baseURL } from "../../URL";

const GetAccountDetails = async (config: any) => {
    try {
        const response = await axios.get(`${baseURL}/administrator/account`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetAllAdmins = async (config: any) => {
    try {
        const response = await axios.get(`${baseURL}/administrator`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const CreateAdmin = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/administrator/create`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetAllManagers = async (config: any) => {
    try {
        const response = await axios.get(`${baseURL}/manager`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const CreateManager = async (postData: any, config: any) => {
    try {
        const response = await axios.post(`${baseURL}/manager/create`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetFarmers = async (currentPage: any, pageSize: any, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/farmers?page=${currentPage}&size=${pageSize}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const GetFilteredFarmers = async (gender: any, params: any, currentPage: any, pageSize: any, config: any) => {
    try {
        const baseURL = 'https://acresal.kbwprjs.com/main/api/v1/farmers/filter';

        let url;
        if (gender && gender.length > 0) {
            url = `${baseURL}/${params}?gender=${encodeURIComponent(gender)}&page_size=${encodeURIComponent(pageSize)}`;
        } else {
            url = `${baseURL}/${params}?page=${encodeURIComponent(currentPage)}&page_size=${encodeURIComponent(pageSize)}`;
        }

        // Make the axios GET request with the constructed URL and config
        const response = await axios.get(url, config);
        return response.data;
    } catch (error: any) {
        // Check if the error has a response with a data message
        if (error?.response?.data?.message === undefined) {
            throw error.message;
        } else {
            throw error?.response?.data?.message;
        }
    }
};


const GetFarmerDetails = async (id: string, config: any) => {
    try {
        const response = await axios.get(`${baseURL}/farmers/${id}`, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
const UpdateFarmerDetails = async (id: any, postData: any, config: any) => {
    try {
        const response = await axios.patch(`${baseURL}/farmers/${id}`, postData, config);
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === undefined) {
            throw error.message
        } else {
            throw error?.response?.data?.message;
        }
    }
};
export { GetAllAdmins, CreateAdmin, UpdateFarmerDetails, GetAllManagers, CreateManager, GetFarmers, GetFilteredFarmers, GetFarmerDetails, GetAccountDetails }