import React, { createContext, useState, useContext, ReactNode } from "react";
type AuthContextType = {
  authUser: any;
  setAuthUser: React.Dispatch<React.SetStateAction<any>>;
  config: { headers: { Authorization: string } };
  token: any;
  setToken: React.Dispatch<React.SetStateAction<any>>;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<any>>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthContextProviderProps = {
  children: ReactNode;
};

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [authUser, setAuthUser] = useState<any | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [activePage, setActivePage] = useState<string>("dashboard");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        config,
        token,
        setToken,
        activePage,
        setActivePage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider"
    );
  }
  return context;
};
