import React from "react";
const TrainingListings = () => {
  const tbHeader = [
    "Category",
    "Topic",
    "State",
    "LGA",
    "StartDate",
    "EndDate",
    "Status",
  ];
  return (
    <div>
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        <h1 className="text-lg font-semibold mb-5">Trainings</h1>
      </div>
      {/* Table Header */}
      <table className="w-full bg-white">
        <thead className="">
          <tr className="border-b border-b-[#E1E1E1]">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-4 text-left text-xs whitespace-nowrap "
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
      </table>
    </div>
  );
};

export default TrainingListings;
