import React from "react";
import { PrimaryLink } from "../../../components/links";
import { TableData } from "../../../components/layouts/Table";
import { Spinner } from "../../../components/generic";
import { FiPlus } from "react-icons/fi";
import useManagerHook from "../../../hooks/useManagerHook";
import { useAuthContext } from "../../../context/AuthContext";
const ManagersListings = () => {
  const tbHeader = [
    "Manager",
    "Phone Number",
    "State",
    "LGA",
    "Status",
    "Actions",
  ];
  const { managers, loading } = useManagerHook();
  const { authUser } = useAuthContext();
  return (
    <div>
      {/* SPinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="w-full flex justify-between items-center">
        <h1 className="text-lg font-semibold">Managers</h1>
        {authUser?.account?.Role === "Super Admin" && (
          <PrimaryLink
            path={`/manager/add`}
            text={"New Manager"}
            width={"w-[160px]"}
            Icon={FiPlus}
          />
        )}
        {authUser?.account?.Role === "Admin" && (
          <PrimaryLink
            path={`/manager/add`}
            text={"New Manager"}
            width={"w-[160px]"}
            Icon={FiPlus}
          />
        )}
      </div>
      {/* Table Header */}
      <table className="w-full bg-white">
        <thead className="">
          <tr className="border-b border-b-[#E1E1E1]">
            {tbHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs whitespace-nowrap"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        {/* Table content */}
        {managers.map((item: any, index) => (
          <tr
            className={`${
              index % 2 === 0 ? " bg-[#F9FAFC]" : "bg-white"
            } border-b border-b-[#E1E1E1]`}
            key={index}
          >
            <td className="px-6 py-2">
              <p className="text-sm font-medium text-black">{`${
                item?.First_Name
              } ${item?.Middle_Name ? item?.Middle_Name : ""} ${
                item?.Last_Name
              }`}</p>
              <p className="text-xs text-zinc-500">{`${item?.Email_Address}`}</p>
            </td>
            <TableData item={item?.Phone_Number} />
            <TableData item={item?.State} />
            <TableData item={item?.LGA} />
            <TableData item={item?.user_type} />
            <td className="">
              <div className="flex items-center px-6 py-3">
                <div className="w-[6px] h-[6px] bg-primary rounded-full mr-2"></div>
                <p className="text-xs">Active</p>
              </div>
            </td>
            {/* <td>
                <div className="flex items-center px-6 py-3">
                  <Link to={`/${slug}/forms/details/${item.id}`}>
                    <FaEdit className="text-primary mx-4 text-lg" />
                  </Link>
                  <RiDeleteBin6Line className="text-red-600 text-lg" />
                </div>
              </td> */}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default ManagersListings;
