import React, { useEffect, useState } from "react";
import { BackLink, PrimaryLink } from "../../../components/links";
import { Button, InputField, SelectField } from "../../../components/forms";
import { useForm } from "react-hook-form";
import { emailReg, numReg, textReg } from "../../../utils/regex";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { states } from "../../../utils/data/states";
import { stateslga } from "../../../utils/data/statelga";
import { CreateAdmin } from "../../../utils/services/api/users";
import { useNavigate } from "react-router-dom";

const AddAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [filteredLga, setFilteredLga] = useState([""]);
  const { control, handleSubmit, getValues, watch } = useForm();
  const { config } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    const selectedState = getValues("State");

    const filteredCity = stateslga.find((item) => item.state === selectedState);
    const newFilteredCity = filteredCity === undefined ? [] : filteredCity.lgas;
    setFilteredLga(newFilteredCity as any);
    // eslint-disable-next-line
  }, [states, getValues, watch("State")]);
  const onSubmit = async (d: any) => {
    setLoading(true);
    try {
      setLoading(true);
      await CreateAdmin(d, config);
      setLoading(false);
      navigate("/admins");
      toast.success("Admin Added Successfully");
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      {/* Spinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title="New Admin" />
      </div>
      {/* Body */}
      <div className="w-full h-[100vh] flex items-center justify-center bg-white py-8 px-20 drop-shadow-xs rounded-sm">
        {/* Form Content */}
        <div className="w-[60%]">
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="First_Name"
                title="First Name"
                placeholder="Enter First Name"
                control={control}
                rules={{
                  required: "First Name is required",
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <InputField
                name="Middle_Name"
                title="Middle Name"
                placeholder="Enter Middle Name (Optional)"
                control={control}
                rules={{
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="Last_Name"
                title="Last Name"
                placeholder="Enter Last Name"
                control={control}
                rules={{
                  required: "Last Name is required",
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="Role"
                rules={{ required: "Role is required" }}
                placeholder="Choose Select"
                title="Role"
                options={["Super Admin", "Admin", "MEL", "State Coordinator"]}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="Phone_Number"
                title="Phone Number"
                placeholder="Enter Phone Number"
                control={control}
                rules={{
                  required: "Phone Number is required",
                  pattern: {
                    value: numReg,
                    message: "Only Number are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <InputField
                name="Email_Address"
                title="Email Address"
                placeholder="Enter Email Address"
                control={control}
                rules={{
                  required: "Email Address is required",
                  pattern: {
                    value: emailReg,
                    message: "Invalid Email Address",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="State"
                rules={{ required: "State is required" }}
                placeholder="Select State"
                title="State"
                options={states}
              />
            </div>
            {/* LGA */}
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="LGA"
                rules={{ required: "LGA is required" }}
                placeholder="Select LGA"
                title="LGA"
                options={filteredLga}
              />
            </div>
          </div>

          {/* Buttons */}
          <div className="w-full flex items-center justify-between">
            <div className="w-[120px]">
              <PrimaryLink path="/admins" outlined text="Cancel" />
            </div>
            <Button
              onBtnClick={handleSubmit(onSubmit)}
              text="Add"
              width={120}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
