import React, { useEffect, useState } from "react";
import { BackLink } from "../../../components/links";
import { Button, InputField, SelectField } from "../../../components/forms";
import { useForm } from "react-hook-form";
import { numReg, textReg } from "../../../utils/regex";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../../components/generic";
import { states } from "../../../utils/data/states";
import { stateslga } from "../../../utils/data/statelga";
import {
  GetFarmerDetails,
  UpdateFarmerDetails,
} from "../../../utils/services/api/users";
import { useNavigate, useParams } from "react-router-dom";

const EditFarmerDetails = () => {
  const [loading, setLoading] = useState(false);
  const [filteredLga, setFilteredLga] = useState([""]);
  const [farmer, setFarmer] = useState<any>(null);
  const { control, handleSubmit, getValues, watch, setValue } = useForm();
  const { id } = useParams();

  const { config } = useAuthContext();
  const navigate = useNavigate();
  const onGetFarmerDetails = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await GetFarmerDetails(id?.toString() as string, config);
      setLoading(false);
      setFarmer(res?.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    onGetFarmerDetails();
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    const selectedState = getValues("State");
    const filteredCity = stateslga.find((item) => item.state === selectedState);
    const newFilteredCity = filteredCity === undefined ? [] : filteredCity.lgas;
    setFilteredLga(newFilteredCity as any);
    // eslint-disable-next-line
  }, [states, getValues, watch("State")]);
  useEffect(() => {
    setValue("FirstName", farmer?.FirstName);
    setValue("MiddleName", farmer?.MiddleName);
    setValue("LastName", farmer?.LastName);
    setValue("Gender", farmer?.Gender);
    setValue("DateOfBirth", farmer?.DateOfBirth);
    setValue("Address", farmer?.Address);
    setValue("State", farmer?.State);
    setValue("Lga", farmer?.Lga);
    setValue("Ward", farmer?.Ward);
    setValue("Phone", farmer?.Phone);
  }, [farmer, setValue]);
  const onSubmit = async (d: any) => {
    setLoading(true);
    try {
      setLoading(true);
      await UpdateFarmerDetails(id, d, config);
      setLoading(false);
      navigate(-1);
      toast.success("Manager Details Updated");
    } catch (error: any) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      {/* Spinner */}
      <Spinner loading={loading} />
      {/* Header */}
      <div className="flex justify-between items-center">
        <BackLink title="Edit Farmer Details" />
      </div>
      {/* Body */}
      <div className="w-full h-[100vh] flex items-center justify-center bg-white py-8 px-20 drop-shadow-xs rounded-sm">
        {/* Form Content */}
        <div className="w-[60%]">
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="FirstName"
                title="First Name"
                placeholder="Enter First Name"
                control={control}
                rules={{
                  required: "First Name is required",
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <InputField
                name="MiddleName"
                title="Middle Name"
                placeholder="Enter Middle Name (Optional)"
                control={control}
                rules={{
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="LastName"
                title="Last Name"
                placeholder="Enter Last Name"
                control={control}
                rules={{
                  required: "Last Name is required",
                  pattern: {
                    value: textReg,
                    message: "Only Text are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <InputField
                control={control}
                name="DateOfBirth"
                placeholder="Select a date"
                title="Date of Birth"
                type="date" // Set type to "date" for a date input
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <InputField
                name="Phone"
                title="Phone Number"
                placeholder="Enter Phone Number"
                control={control}
                rules={{
                  required: "Phone Number is required",
                  pattern: {
                    value: numReg,
                    message: "Only Number are allowed",
                  },
                }}
              />
            </div>
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="Gender"
                rules={{ required: "Gender is required" }}
                placeholder="Select"
                title="Gender"
                options={["Male", "Female"]}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="State"
                rules={{ required: "State is required" }}
                placeholder="Select State"
                title="State"
                options={states}
              />
            </div>
            {/* LGA */}
            <div className="w-[45%]">
              <SelectField
                control={control}
                name="Lga"
                rules={{ required: "LGA is required" }}
                placeholder="Select LGA"
                title="LGA"
                options={filteredLga}
              />
            </div>
          </div>
          {/* Address */}
          <InputField
            name="Address"
            title="Address"
            placeholder="Enter Address"
            control={control}
            rules={{
              required: "Address is required",
            }}
          />
          {/* Buttons */}
          <div className="w-full flex items-center justify-between">
            <div className="w-[120px]">
              <Button
                onBtnClick={() => navigate(-1)}
                text="Cancel"
                width={120}
                outlined
              />
            </div>
            <Button
              onBtnClick={handleSubmit(onSubmit)}
              text="Update"
              width={120}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFarmerDetails;
